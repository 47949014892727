import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ScfToastrService } from 'scf-library';
import * as _ from 'lodash';

import { Activity } from '../../../administration/activity/activity';
import { AppInsightsMonitoringService } from '../../monitoring/appInsights/app-insights-monitoring.service';
import { AuthResponse } from '../../../login/auth-response';
import { ChangePasswordData } from '../../../administration/change-password/change-password-data';
import { CONSTANTS } from '../../constants';
import { ENVIRONMENT } from '../../../../environments/environment';
import { HandleError } from './../../../utils/utils';
import { IMenuInterface } from '../../../models/menu-interface';
import { JwtInterface } from '../../jwt.interface';
import { LabelService } from '../../../administration/label-management/label.service';
import { MobileConfig } from '../../constants/mobile-config';
import { Payload } from '../interfaces/payload.interface';
import { ROUTES, PARENTS } from '../routes/routes.mapping';
import { TokenResponseInterface } from '../../token-response.interface';
import { User } from '../../../administration/user/user';
import { Warehouse } from '../../../catalog/warehouse/warehouse';
import { WepError } from '../../wep-error';

@Injectable({providedIn : 'root'})
export class AuthService {
  private changePasswordData: BehaviorSubject<ChangePasswordData>;
  private isLoggedInSubject: Subject<boolean>;
  private wrongUsers: BehaviorSubject<Map<string, number>>;
  public redirectUrl: string;
  public msgs: any;
  constructor( private http: HttpClient,
    private router: Router,
    private appInsightsMonitoringService: AppInsightsMonitoringService,
    private labelService: LabelService,
    private notifier: ScfToastrService) {
      this.msgs = {
        errorTitle: this.labelService.labelText('errorTitle'),
        passwordExpired: this.labelService.labelText('passwordExpired'),
        passwordExpiresSoon: this.labelService.labelText('passwordExpiresSoon')
      };
      this.changePasswordData = new BehaviorSubject<ChangePasswordData>(new ChangePasswordData());
      this.isLoggedInSubject = new Subject<boolean>();
      this.wrongUsers = new BehaviorSubject<Map<string, number>>(new Map());
  }

  /**
   * @description Monitoring user login, authenticating user and login event.
   * @param {string} nickname - User id identifier.
   * @param {number} warehouseId - User account identifier.
   * @return {void}
   */
  private monitoringUserAuthentication(nickname: string, warehouseId: number) {
    this.appInsightsMonitoringService.setAuthenticatedUserId(nickname, warehouseId.toString(), true);
    this.appInsightsMonitoringService.logEvent('User login', { 'user': nickname , 'warehouse': warehouseId.toString() });
  }

  /**
   * @description Log the user in, and get the token.
   * @param {string} nickname User's nickname.
   * @param {string} password User's password.
   * @param {number} warehouseId User's warehouse id.
   * @param {number} accountId User's account id.
   * @param {number} equipmentId User's equipment id.
   * @param {number} mobile Bit to know if user  use a rf
   * @return {Observable<R>} Token or failure response.
   */
  public login(nickname: string, password: string, warehouseId: number, accountId: number, equipmentId: number,
    mobile: boolean): Observable<any> {
    if (equipmentId) {
      this.storeEquipmentId(equipmentId);
    }
    let payload: Payload = {
      client_id: mobile ? ENVIRONMENT.RFCLIENTID : ENVIRONMENT.CLIENTID,
      client_secret: mobile ? ENVIRONMENT.RFCLIENTSECRET : ENVIRONMENT.CLIENTSECRET,
      username: nickname,
      password: password,
      accessAttempts: this.getAccessAttemptsByNickname(nickname),
      warehouse_id: warehouseId,
      grant_type: CONSTANTS.ACCESS_TOKEN,
      account_id: accountId,
      equipment_id: this.retrieveEquipmentId()
    };
    let request = this.http.post(ENVIRONMENT.API + '/auth/token', payload)
    .pipe(map((response: TokenResponseInterface) => {
      this.storeToken(response.access_token);
      this.storeRefreshToken(response.refresh_token);
      this.sendLoggedInStatus(true);
      if (!_.isNull(warehouseId)) {
        this.monitoringUserAuthentication(nickname, warehouseId);
      }
      this.getAllowedActivities().subscribe((routes: Activity[]) => {
        return routes;
      }, (error) => {
        throwError(error);
      });
      return response;
    }
    ))
    .pipe(catchError(HandleError.handleErrorObservable));
    return request;
  }

  /**
   * @description Retrieves the equipment ID from localstorage
   * @return {number}
   */
  public retrieveEquipmentId(): number {
    return _.toInteger(localStorage.getItem(CONSTANTS.EQUIPMENT_ID));
  }

  /**
   * @description Stores the token on the id_token key.
   * @param {string} token - The JWT string.
   * @return {void}
   */
  public storeToken(token: string): void {
    localStorage.setItem(CONSTANTS.ID_TOKEN, token);
  }

  /**
   * @description Stores the refresh token on the refresh_token key.
   * @param {string} token JWT string.
   * @return {void}
   */
  public storeRefreshToken(token: string): void {
    localStorage.setItem(CONSTANTS.REFRESH_TOKEN, token);
  }

  /**
   * @description Gets all the allowed activities given the user (represented with the token)
   * and then calls the formAllowedRoutesMethod
   * @returns {Observable<any>}
   */
  public getAllowedActivities(): Observable<Activity[]> {
    let httpObservable = this.http.get<Activity[]>(ENVIRONMENT.API + '/auth/activities')
      .pipe(map((activities: Activity[]) => {
          this.formAllowedRoutes(activities);
        return activities;
      }))
      .pipe(catchError((error: any) => throwError(error || 'Server error')));
    return httpObservable;
  }

  /**
   * @description This Method forms the tree for the side menu. It looks maps each activities' "name"
   * @param {Activity[]} activities Array about activities object.
   * attribute to the corresponding "label" route atribute, then sets it to the localStorage
   * @return {void}
   */
  public formAllowedRoutes(activities: Activity[]): void {
    let newActivities: Activity[] = _.clone(activities);
    let parent: IMenuInterface;
    let formedRoutes: IMenuInterface[] = [];
    let route: any;
    let mobile = ENVIRONMENT.ALWAYS_ON_MOBILE ? ENVIRONMENT.ALWAYS_ON_MOBILE : window.screen.width < MobileConfig.WINDOW_WIDTH;

    if (!mobile) {
      _.remove(newActivities, { name : CONSTANTS.ACTIVITY_PICKING_LIST });
      _.remove(newActivities, { name : CONSTANTS.ACTIVITY_PICKING_PALLET });
    }
    newActivities.forEach((activity: Activity) => {
      let activityConditions = { 'name': activity.name };
      if (_.isEqual(activity.name, CONSTANTS.PICKING_WIHOUT_ORDER_ACTIVITY)) {
        activityConditions = { 'name': CONSTANTS.PICKING_ORDER_CONS };
      }
      route = _.clone(_.find(ROUTES, activityConditions));
      if (!_.isEqual(route, undefined)) {
        let parentFindConditions = {'name': route.parent};
        parent = <IMenuInterface> _.clone(_.find(formedRoutes, parentFindConditions));
        if (_.isEqual(parent, undefined)) {
          parent = <IMenuInterface>_.find(PARENTS, parentFindConditions);
          parent.items = [];
          formedRoutes.push(parent);
        }
        delete route.parent;
        parent.items.push(route);
      } else {
        route = _.find(PARENTS, activityConditions);
        if (!_.isEqual(route, undefined) && _.isEqual(route.items, undefined)) {
          delete route.items;
          formedRoutes.push(route);
        }
      }
    });
    _.forEach(formedRoutes, (routing: IMenuInterface) => {
      if (!_.isEqual(routing.items, undefined)) {
        if ( _.isEqual(routing.icon,  CONSTANTS.REALLOCATE.toLocaleLowerCase())) {
          routing.items = _.orderBy(routing.items, CONSTANTS.NAME, CONSTANTS.SORTING_ORDER_DESC);
        } else {
          routing.items = _.orderBy(routing.items, CONSTANTS.ORDER_BY_TITLE, CONSTANTS.SORTING_ORDER);
        }
        routing.items = _.uniqBy(routing.items, 'name');
      }
      return routing;
    });
    let stringifiedRoutes: string = JSON.stringify(_.orderBy(formedRoutes, CONSTANTS.SORTING_ATTRIBUTE, CONSTANTS.SORTING_ORDER));
    this.setAllowedRoutes(stringifiedRoutes);
  }

  /**
   * @description Sets the allowed routes in local storage.
   * @param {string} stringRoutes String about system routes.
   * @return {void}
   */
  private setAllowedRoutes(stringRoutes: string): void {
    localStorage.setItem(ENVIRONMENT.ALLOWEDROUTES, stringRoutes);
  }

  /**
   * @description This method logs the user out of the system.
   * @param {boolean} fromHttp Parameter that receives the method to validate with jwt
   * and remove the user from the active session.
   * @return {void}
   */
  public logout(fromHttp: boolean = false): void {
    let logout: Observable<Object>;
    let mobile: boolean;
    mobile = window.screen.width < MobileConfig.WINDOW_WIDTH;
    if (mobile) {
      logout = this.http.post(ENVIRONMENT.API + '/auth/logout-rf', null);
    } else {
      logout = this.http.post(ENVIRONMENT.API + '/auth/logout', null);
    }
    logout.subscribe(() => {
      this.removeToken();
      this.removeRefreshToken();
      this.removeAllowedRoutes();
      localStorage.clear();
      if (!fromHttp) {
        this.sendLoggedInStatus(false);
        this.resetChangePasswordData();
      }
      this.appInsightsMonitoringService.clearUserId();
      this.router.navigate([CONSTANTS.LOGIN_URL]);
    }, () => {
      this.removeToken();
      this.removeRefreshToken();
      this.removeAllowedRoutes();
      if (!fromHttp) {
        this.sendLoggedInStatus(false);
        this.resetChangePasswordData();
      }
      this.router.navigate([CONSTANTS.LOGIN_URL]);
    });
  }

  /**
   * @description Removes the JWT token from the system.
   * @return {void}
   */
  public removeToken(): void {
    localStorage.removeItem(CONSTANTS.ID_TOKEN);
  }

  /**
   * @description Removes the JWT token from the system.
   * @return {void}
   */
  public removeRefreshToken(): void {
    localStorage.removeItem(CONSTANTS.REFRESH_TOKEN);
  }

  /**
   * @description Removes the allowed routes from local storage
   * @return {void}
   */
  public removeAllowedRoutes(): void {
    localStorage.removeItem(ENVIRONMENT.ALLOWEDROUTES);
  }

  /**
   * @description Returns the subject monitoring logged in status. Only for auth service use.
   * @return {Subject<boolean>}
   */
  public getLoggedInSubject(): Subject<boolean> {
    return this.isLoggedInSubject;
  }

  /**
   * @description Returns the subject monitoring logged in status as an Observable
   * @return {Observable<boolean>}
   */
  public getLoggedInObservable(): Observable<boolean> {
    return this.isLoggedInSubject.asObservable();
  }

  /**
   * @description Sends the status of the subject per request.
   * @param {boolean} isLoggedIn Param value without response about monitoring status.
   */
  public sendLoggedInStatus(isLoggedIn: boolean) {
    this.isLoggedInSubject.next(isLoggedIn);
  }

  /**
   * @description Gets the user information from the token
   * @return {Observable<User>}
   */
  public getUserInfo(): Observable<User> {
    let httpObservable = this.http.get(ENVIRONMENT.API + '/auth/user')
      .pipe(map((user: User) => {
        return user;
      })).pipe(catchError((error: any) =>
        throwError(error || 'Server error')));
    return httpObservable;
  }

  /**
   * @description Gets the info from the token
   * @return {JwtInterface} With the following parameters
   * iat - Issued at.
   * eat - Expires at.
   * warehouseId - Warehouse Id.
   * accountId - Account Id.
   * userId - User Id.
   */
  public getTokenInfo(): JwtInterface {
    let jwtHelper: JwtHelperService = new JwtHelperService();
    let stringToken = this.retrieveToken();
    let decodedToken: JwtInterface = jwtHelper.decodeToken(stringToken);
    return decodedToken;
  }

  /**
   * @description Retrieves token from the local storage
   * @returns {string|null}
   */
  public retrieveToken(): string {
    return localStorage.getItem(CONSTANTS.ID_TOKEN);
  }

  /**
   * @description Gets the allowed routes from local storage
   * @return {RouteInterface[]} The formed Routes
   */
  public getAllowedRoutes(): IMenuInterface[] {
    let stringRoutes = localStorage.getItem(ENVIRONMENT.ALLOWEDROUTES);
    let jsonRoutes: IMenuInterface[] = JSON.parse(stringRoutes);
    return jsonRoutes;
  }

  /**
   * @description Gets the list of warehouses for user to select from
   * @param {User} user User's nickname to search warehouses related
   * @return {Observable<Warehouse[]>} - List of Warehouses
   */
  public getWarehousesInfoByUser(user: User): Observable<Warehouse[]> {
    let criteria = [{ 'field': 'nickname', 'condition': '=', 'value': user.nickname }];
    return this.http.get(ENVIRONMENT.API + '/warehouses?criteria=' + JSON.stringify(criteria))
      .pipe(map((warehouse: Warehouse[]) => {
          return warehouse;
      }, (error: WepError) => {
        this.notifier.errorAlert(this.labelService.getWepError(error.message));
      }));
  }

  /**
   * @description Returns true if the localstorageitem id_token is not expired
   * according to the JWT expiry time.
   * @returns {boolean}
   */
  public isAuthenticated(): boolean {
    try {
      let token: string = this.retrieveToken();
      let allowedRoutes: IMenuInterface[] = this.getAllowedRoutes();
      let jwtHelper: JwtHelperService = new JwtHelperService();
      let decodedToken: JwtInterface = jwtHelper.decodeToken(token);
      if (!_.isNull(token) && !_.isNull(allowedRoutes) && !_.isEqual(decodedToken.warehouseId, CONSTANTS.ZERO)) {
        return true;
      }
      return false;
    } catch (exception) {
      this.removeToken();
      this.removeAllowedRoutes();
      return false;
    }
  }

  /**
   * @description Looks up an attribute recursively in a tree
   * @param {string} lookupString.
   * @param {string} atribute.
   * @param {RouteInterface[]} routeTree.
   * @returns {RouteInterface}
   */
  public findInTree(lookupString: string, attribute: string, routeTree: IMenuInterface[]): IMenuInterface {
    let foundItem: IMenuInterface = null;
    let formattedRoute: string[] = [lookupString];
    for (let routeItem of routeTree) {
      let attributeValue: any = routeItem[attribute];
      if (!_.isEqual(attributeValue, undefined) && _.isEqual(attributeValue, formattedRoute)) {
        return routeItem;
      }
      if (_.isEqual(attributeValue, undefined)) {
        // TODO: Analyze how to find in tree recursive.
        // foundItem = this.findInTree(lookupString, attribute, routeItem.items);
      }
    }
    return foundItem;
  }

  /**
   * @description This method recursively looks up the route in the allowed routes tree.
   * If the route is found returns true, otherwise returns false
   * @param {string} route - Name of the route.
   * @returns {boolean}
   */
  public isAuthorized(route: string): boolean {
    let routeTree = this.getAllowedRoutes();
    let routeObject: IMenuInterface;
    routeObject = this.findInTree(route, 'routerLink', routeTree);
    if (!_.isNull(routeObject)) {
      return true;
    }
    return false;
  }

  /**
   * @description Refreshes the user token.
   * @param {number} warehouseId - User's warehouse id.
   * @param {number} accountId - User's account id.
   * @returns {Observable<R>} - The token or the failure response.
   */
  public refreshToken(warehouseId: number = 1, accountId: number = 1): Observable<any> {
    let payload: Payload = {
      client_id: ENVIRONMENT.CLIENTID,
      client_secret: ENVIRONMENT.CLIENTSECRET,
      warehouse_id: warehouseId,
      grant_type: CONSTANTS.REFRESH_TOKEN,
      account_id: accountId,
      equipment_id: this.retrieveEquipmentId(),
      refresh_token: this.retrieveRefreshToken()
    };
    let loginObservable = this.http.post(ENVIRONMENT.API + '/auth/token', payload)
      .pipe(map(this.handleTokenRefresh))
      .pipe(catchError((error: any) => {
        return throwError(error);
      }));
    return loginObservable;
  }

  /**
   * @description This method may use a refresh token to get a new access token issued by the authentication server.
   * @param {TokenResponseInterface} token Token Identifier.
   * @return {any}
   */
  public handleTokenRefresh(token: TokenResponseInterface): any {
    let body = token;
    return body || {};
  }

  /**
   * @description Retrieves refresh token from the local storage
   * @returns {string|null}
   */
  public retrieveRefreshToken(): string {
    return localStorage.getItem(CONSTANTS.REFRESH_TOKEN);
  }

  /**
   * @description This method redirects the router to the destination url if it is authenticated.
   * @return {void}
   */
  public redirect(): void {
    this.router.navigate([this.redirectUrl]);
  }

  /**
   * @description Stores the equipment ID to localstorage.
   * @return {void}
   */
  public storeEquipmentId(equipmentId: number): void {
    localStorage.setItem(CONSTANTS.EQUIPMENT_ID, equipmentId.toString());
  }

  /**
   * @description Set time zone related
   * @param {string} timeZone String about system routes.
   * @return {void}
   */
  public setTimeZone(timeZone: string): void {
    localStorage.setItem(CONSTANTS.TIME_ZONE, timeZone);
  }

  /**
   * @description Returns the subject of change password data
   * @returns {BehaviorSubject<ChangePasswordData>} - Object with data required to active route
   */
  public getChangePasswordData(): BehaviorSubject<ChangePasswordData> {
    return this.changePasswordData;
  }

  /**
   * @description Returns the subject as an observable
   * @returns {Observable<ChangePasswordData>} - Object with data required to active route
   */
  public getChangePasswordDataObservable(): Observable<ChangePasswordData> {
    return this.changePasswordData.asObservable();
  }

  /**
   * @description Send the value of subject to evaluate if is first access o not
   * @param {boolean} changePasswordData - Object with data needed to active routes
   * @returns {void}
   */
  public sendChangePasswordData(changePasswordData: ChangePasswordData): void {
    this.changePasswordData.next(changePasswordData);
    if (changePasswordData.isChangePasswordRequired) {
      this.router.navigate([CONSTANTS.CHANGE_PASSWORD_PATH]);
    }
  }

  /**
   * @description Set tha request value to change password
   * @param {boolean} changePassword - True to goes to the page, false if not
   * @returns {void}
   */
  public goesToChangePassword(changePassword: boolean): void {
    if (!this.changePasswordData.value.isChangePasswordRequired) {
      this.changePasswordData.value.isRequestByUser = changePassword;
      if (changePassword) {
        this.router.navigate([CONSTANTS.CHANGE_PASSWORD_PATH]);
      }
    } else {
      this.resetIsChangePasswordRequestByUser();
    }
  }

  /**
   * @description Resets the isRequestByUser flag in change password data object
   * @returns {void}
   */
  public resetIsChangePasswordRequestByUser(): void {
    this.changePasswordData.value.isRequestByUser = false;
  }

  /**
   * @description Checks if password expires soon and shows the alert to update
   * @returns {void}
   */
  public showUpdatePasswordMessage(): void {
    this.changePasswordData.subscribe((changePassword: ChangePasswordData) => {
      if (!_.isNil(changePassword.daysRemaining) && changePassword.daysRemaining <= CONSTANTS.THREE
        && !changePassword.passwordUpdateAlertShowed) {
        const errorMessage: string = _.isEqual(changePassword.daysRemaining, CONSTANTS.ZERO)
          ? this.msgs.passwordExpired : this.msgs.passwordExpiresSoon;
        this.notifier.warningAlert(errorMessage);
        this.changePasswordData.value.passwordUpdateAlertShowed = true;
        if (_.isEqual(changePassword.daysRemaining, CONSTANTS.ZERO) && !changePassword.isAdminRole) {
          this.logout();
        }
      }
    });
  }

  /**
   * @description Reset values of change password data subject
   * @returns {void}
   */
  public resetChangePasswordData(): void {
    this.changePasswordData.next(new ChangePasswordData());
  }

  /**
   * @description Set the access attempts for non-existent user
   * @param {string} nickname - User nickname
   * @param {AuthResponse} authResponse - Object with attempts value
   */
  public addFailedAttemptToUser(nickname: string, authResponse: AuthResponse): void {
    this.wrongUsers.value.set(_.trim(nickname), authResponse.attemptsMade);
  }

  /**
   * @description Gets the access attempts of a non-existent user
   * @param {string} nickname - User to found
   * @returns {number} - Acces attempts
   */
  public getAccessAttemptsByNickname(nickname: string): number {
    return this.wrongUsers.value.get(nickname);
  }

  /**
   * @description Reset wrong users
   * @returns {void}
   */
  public resetWrongUsers(): void {
    this.wrongUsers.next(new Map());
  }
}
