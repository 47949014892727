import { catchError } from 'rxjs/internal/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import * as moment from 'moment';

import { CONSTANTS } from '../../../shared/constants';
import { Criteria } from '../../../utils/criteria/criteria';
import { ENVIRONMENT } from '../../../../environments/environment';
import { HandleError } from '../../../utils/utils';
import { InventoryDetail } from '../../../inventory/inventory-detail';
import { InventoryHeader } from '../../../inventory/inventory-header';
import { InventoryLocation } from './inventory-location';
import { InventoryStatus } from '../../../catalog/inventory-status/inventory-status';
import { Location } from '../../../catalog/location/location';
import { Lot } from '../../../catalog/product/lot/lot';
import { Lpn } from '../../../lpn/lpn';
import { Picking } from '../../picking/picking';
import { PickingByEachTemporal } from '../../picking-by-each/picking-by-each-process/picking-by-each-temporal';
import { PickingByEachWrapper } from '../../picking-by-each/picking-by-each-wrapper';
import { PickingDetail } from '../../picking/detail/picking-detail';
import { PickingRf } from './picking-rf';
import { PickingSuggestedLocation } from './picking-suggested-location';
import { PickingTaskWrapperDetail } from '../../../shared/templates/html/task-wrapper/picking-task-detail';
import { Product } from '../../../catalog/product/product';
import { Session } from '../../../session/session';
import { SessionService } from '../../../session/session.service';
import { ShipmentOrder } from '../../picking/shipment-order/shipment-order';
import { ShipmentOrderDetail } from '../../picking/shipment-order/shipment-order-detail';
import { SimplePickingWrapper } from './simple-picking.wrapper';
import { TaskDetail } from '../../task/task-detail';

@Injectable()
export class PickingRfService {
  private subject: BehaviorSubject<PickingByEachWrapper>;

  constructor (private http: HttpClient, private sessionService: SessionService) {
  }

  /**
  * @description Obtains the expiration date for the specified sku and lot
  * @param {sku} string - sku for the product to look up the lot for
  * @param {lot} string - specific lot to get the expiration date from
  * @return {Observable<Lot>} Lot - Lot object
  */
  public findLotBySku(sku: string, lot: string): Observable<Lot> {
    let session: Session = this.sessionService.getUserSession();
    return this.http.get<Lot>(ENVIRONMENT.API + '/products/' + sku + '/lots/' + lot + '?session=' + JSON.stringify(session))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
  * @description Obtains suggested locations for the selected product to ship
  * @param {PickingSuggestedLocation} productToFind - Product that will be pick
  * @return {Observable<InventoryLocation[]>} - Object containing the locations
  */
  public getSuggestedLocations(productToFind: PickingSuggestedLocation): Observable<InventoryLocation[]> {
    return this.http.get<InventoryLocation[]>(ENVIRONMENT.API +
      '/picking/find-suggested-locations' + '?body=' + JSON.stringify(productToFind.toJSON()))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
  * @description Obtains locations for the name
  * @param {string} name - name of the location
  * @param {number} accountId - The account id to look up for
  * @return {Observable<Location>} - Location Object
  */
  public getLocation(name: string, accountId: number): Observable<Location> {
    let criteria = [
      { 'field': 'area.is_picking_part', 'condition': '=', 'value': true, operator: 'or', chain: true },
      { 'field': 'area.is_picking_case', 'condition': '=', 'value': true, operator: 'or', chain: true },
      { 'field': 'area.is_picking_pallet', 'condition': '=', 'value': true, operator: 'or', chain: true }
    ];
    return this.http.get<Location>(ENVIRONMENT.API + '/locations/' + name + '?accountId=' + accountId +
      '&criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
  * @description Creates a new picking line and adds it to the inventory
  * @param {Object} newPicking - json formatted object with all the needed parameters
  * @param {number} accountId - The account id to look up for
  * @return {Observable<Picking} Picking - Picking object
  */
  public newPicking(newPicking: Object, accountId: number): Observable<Picking> {
    let session: any = this.sessionService.getUserSession();
    let newPickingSession: any = newPicking;
    newPickingSession.session = session;
    newPickingSession.session.equipmentId = session.equipmentId;

    return this.http.post<Picking>(ENVIRONMENT.API + '/picking/confirmPicking?accountId=' + accountId, newPickingSession)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Method that generates a new folio consecutive
   * @param {number} accountId Id of account selected.
   * @return {Observable<Picking>}
   */
   public generatePickingFolio(accountId: number): Observable<Picking> {
    return this.http.get<Picking>(ENVIRONMENT.API + '/picking/generateFolio?accountId=' + accountId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Generate the picking route for shipment order
   * @param {SimplePickingWrapper} simpleWrapper shipment order with folio and account to get route picking
   * @return {Observable<SimplePickingWrapper>}
   */
  public generatePickingRoute(simpleWrapper: SimplePickingWrapper): Observable<SimplePickingWrapper> {
    return this.http.post<SimplePickingWrapper>(ENVIRONMENT.API + '/picking/simpleRoute', simpleWrapper)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
  * @description Creates a new picking line for the current order
  * @param {Object} newPicking JSON formatted object with all the needed parameters
  * @param {number} accountId account identifier.
  * @return {Observable} any - Object containing the new picking order id
  */
  public newPickingOrder(newPicking: Object, accountId: number): Observable<any> {
    return this.http.post(ENVIRONMENT.API + '/picking/confirmSimplePicking?accountId=' + accountId, newPicking)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
  * @description Validates wheter the picking has already been created or not
  * @param {string} folio - The string for the folio it'll look up
  * @param {number} accountId - The account id to look up for
  * @return {Observable<ShipmentOrder>} ShipmentOrder - Order reserved
  */
  public validatePickingOrder(folio: string, accountId: number): Observable<ShipmentOrder> {
    let session: Session = this.sessionService.getUserSession();
    let validatePickingSession: any = { 'session': {} };
    let folioEncoded: string = encodeURIComponent(folio);
    validatePickingSession.session = session;
    return this.http.post<ShipmentOrder>(ENVIRONMENT.API + '/picking/pickOrder/' + folioEncoded +
      '?accountId=' + accountId, validatePickingSession)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
  * @description Validates wheter the picking has already been created or not
  * @param {string} folio - The string for the folio it'll look up
  * @param {number} accountId - The account id to look up for
  * @param {number} pickingType - picking type
  * @return {Observable<Picking>} Picking - Picking object
  */
  public validatePicking(folio: string, accountId: number, pickingType: number): Observable<Picking> {
    let session: Session = this.sessionService.getUserSession();
    let validatePickingSession: any = {'session': {}, 'pickingType': pickingType };
    let folioEncoded: string = encodeURIComponent(folio);
    validatePickingSession.session = session;
    return this.http.post<Picking>(ENVIRONMENT.API + '/picking/validateFolio/' + folioEncoded + '?accountId=' + accountId,
      validatePickingSession)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
  * @description Validates the selected product by user
  * @param {string} sku - product code entered
  * @param {number} accountId - The account id to look up for
  * @return {Observable<Product>} Product - Product object assigned to the response
  */
  public validateSku(sku: string, accountId: number): Observable<Product> {
    let encodedSku = encodeURIComponent(sku);
    return this.http.get<Product>(ENVIRONMENT.API + '/products/' + encodedSku + '/footprints' + '?accountId=' + accountId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Validates the selected poduct, existence, location, or lot by user
   * @param {PickingRf} picking - inventory information to validate
   * @return {Observable<InventoryDetail[]>} - InventoryDetails
   */
  public validateInventory(picking: PickingRf): Observable<InventoryDetail[]> {
    let session: Session = this.sessionService.getUserSession();
    let sessionStr = JSON.stringify(session);
    let criteria: Object[];
    criteria = [
      { 'field': 'invdet.product_id',
        'condition': '=',
        'value': picking.productId
      }, {
        'field': 'invhead.location_id',
        'condition': '=',
        'value': picking.originHeader.locationId
      }];
    if (picking.originHeader.lpn.lpn) {
      criteria.push({
        'field': 'lpn.lpn',
        'condition': '=',
        'value': picking.originHeader.lpn.lpn
      });
    }
    if (picking.dynamicAttribute1) {
      criteria.push({
        'field': 'invdet.dynamic_attribute_1',
        'condition': '=',
        'value': picking.dynamicAttribute1
      });
    }
    if (picking.dynamicAttribute2) {
      criteria.push({
        'field': 'invdet.dynamic_attribute_2',
        'condition': '=',
        'value': picking.dynamicAttribute2
      });
    }
    if (picking.dynamicAttribute3) {
      criteria.push({
        'field': 'invdet.dynamic_attribute_3',
        'condition': '=',
        'value': picking.dynamicAttribute3
      });
    }
    if (picking.lot) {
      criteria.push({
        'field': 'invdet.lot',
        'condition': '=',
        'value': picking.lot
      });
    }
    let criteriaStr = JSON.stringify(criteria);
    return this.http.get<InventoryDetail[]>(ENVIRONMENT.API +
      '/inventory/check-inventory?session=' + sessionStr + '&criteria=' + criteriaStr)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Verify if location or pallet has inventory
   * @param {number} locationId Location id to find inventory or pallet
   * @param {string} lpn lpn to find inventory
   * @param {InventoryDetail} inventoryDetail Inventory detail with product specifications
   * @param {number[]} invenotryStatuses - ids of specific inventory statuses.
   * @param {number} minShelfLife - minimum shelf life days, optional
   * @returns {Observable<InventoryDetail[]>} Found inventory
   */
  public checkInventoryLpn(locationId: number, lpn: string, inventoryDetail?: InventoryDetail,
    inventoryStatuses?: number[], minShelfLife?: number): Observable<InventoryDetail[]> {
    let criteria: Object[] = [];
    if (_.isNil(inventoryDetail)) {
      inventoryDetail = new InventoryDetail();
    }

    criteria.push({ field: 'inventory_detail.quantity', condition: '>', value: CONSTANTS.ZERO });
    if (lpn) {
      criteria.push({ field: 'lpn.lpn', condition: '=', value: lpn });
      criteria.push({ field: 'lpn.is_active', condition: '=', value: CONSTANTS.ONE });
    }

    if (!_.isNil(inventoryDetail.product.id)) {
      criteria.push({ field: 'inventory_detail.product_id', condition: '=', value: inventoryDetail.product.id });
    }

    if (inventoryDetail.lot) {
      criteria.push({ field: 'inventory_detail.lot', condition: '=', value: inventoryDetail.lot });
    }

    if (!_.isEmpty(inventoryStatuses)) {
      criteria.push({ field: 'inventory_detail.inventory_status_id', condition: 'in', value: inventoryStatuses });
    }
    if (minShelfLife && minShelfLife > 0) {
      let dateOffset =  moment().add(minShelfLife, CONSTANTS.DAY).format(CONSTANTS.YEAR_FORMAT);
      criteria.push({
        'field': 'inventory_detail.expiration_date',
        'condition': '>=',
        'value': dateOffset
      });
    }

    let criteriaText: string = JSON.stringify(criteria);
    return this.http.get<InventoryDetail[]>(ENVIRONMENT.API + `/inventory/check-lpn/${locationId}/?criteria=${criteriaText}`)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Moves picked lpn to Shipment location
   * @param {PickingRf} newPicking - json formatted object with all the needed parameters
   * @param {number} accountId - number of the account of the picking
   * @param {string} pickingType - picking type
   * @return {Observable<PickingDetail>} - PickingDetail Object
   */
  public moveToShipment(newPicking: PickingRf, accountId: number, pickingType: string): Observable<PickingDetail> {
    let session: any = this.sessionService.getUserSession();
    let newPickingSession: Object = {
      'pickingId' : newPicking.pickingId,
      'session' : session,
      'lpn' : newPicking.destinationHeader.lpn,
      'pickingType': pickingType
    };

    return this.http.post<PickingDetail>(ENVIRONMENT.API + '/picking/moveToShipment?accountId=' + accountId, newPickingSession)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Moves simple picking lpn to Shipment location
   * @param {PickingRf} newPicking - json formatted object with all the needed parameters
   * @param {number} accountId - number of the account of the picking
   * @param {string} pickingType - picking type
   * @return {Observable<PickingDetail>} - PickingDetail Object
   */
  public shipmentSimplePicking(newPicking: PickingRf, accountId: number, pickingType: string): Observable<PickingDetail> {
    let session: any = this.sessionService.getUserSession();
    let newPickingSession: Object = {
      'pickingId' : newPicking.pickingId,
      'session' : session,
      'lpn' : newPicking.destinationHeader.lpn,
      'pickingType': pickingType
    };

    return this.http.post<PickingDetail>(ENVIRONMENT.API + '/picking-simple/moveToShipment?accountId=' + accountId, newPickingSession)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Check shipment order status
   * @param {string} folio - shipment order folio
   * @return {Observable<void>}
   */
  public checkShipmentOrder(folio: string): Observable<ShipmentOrder> {
    return this.http.put<ShipmentOrder>(ENVIRONMENT.API + '/picking/checkShipmentOrder/' + folio, null)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description get inventory statuses.
   * @param {number} productId - product identifier.
   * @param {number} locationId - location identifier.
   * @param {number} inventoryHeaderId - origin lpn identifier
   * @param {number | undefined} progressionId, progresion value to filter
   * @returns {Observable<InventoryStatus[]>}
   */
  public getInventoryStatuses(productId: number, locationId: number, inventoryHeaderId: number, progressionId?: number): Observable<InventoryStatus[]> {
    let criteria: any[] = [];
    criteria.push({ field: 'inventory_header.location_id', condition: '=', value: locationId });
    criteria.push({ field: 'inventory_detail.product_id', condition: '=', value: productId });
    criteria.push({ field: 'inventory_header.id', condition: '=', value: inventoryHeaderId });
    if (!_.isNil(progressionId)) {
      criteria.push({field: 'progression_inventory_status.progression_id', condition: '=', value: progressionId});
    }
    return this.http.get<InventoryStatus[]>(ENVIRONMENT.API + '/inventory-status-by-location?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description unassigns picking order.
   * @param {string} shipmentOrderFolio - folio for the order to unassign.
   * @returns {Observable<ShipmentOrder>}
   */
  public unassignPickingOrder(shipmentOrderFolio: string): Observable<ShipmentOrder> {
    return this.http.post<ShipmentOrder>(ENVIRONMENT.API + '/picking/unassignOrder', {folio: shipmentOrderFolio})
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets pallets associated to a receipt folio.
   * @param {string} folio  - folio to search pallets.
   * @returns {Observable<string>} Found count of pallets.
   */
  public getCountPalletsByFolio(folio: string): Observable<string> {
    return this.http.get<string>(ENVIRONMENT.API + '/picking/find-count-pallets/' + encodeURIComponent(folio))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets the list of locations
   * @param {string} query Query text to look location for
   * @param {number} userId id of user
   * @return {Observable<Location[]>} Locations's list
   */
  public searchLocations(query: string, userId: number): Observable<Location[]> {
    let criteria: any[] = [];
    criteria.push({ field: 'loc.name', condition: '=', value: query });
    if (userId) {
      criteria.push({ field: 'assignment_zone_user.user_id', condition: '=', value: userId });
    }
    return this.http.get<Location[]>(ENVIRONMENT.API + '/locations?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Validates if the serial exists for the given product
   * @param {string} serialNumber serial number
   * @param {number} productId product id
   * @return {Observable<void} Shipment order details found.
   */
  public validateSerialByProduct(serialNumber: string, productId: number): Observable<void> {
    return this.http.get<void>(ENVIRONMENT.API + '/serial/' + serialNumber + '/product/' + productId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description find shipment orders by criteria
   * @param {ShipmentOrder} shipmentOrder - shipment order object.
   * @returns {Observable<ShipmentOrder[]>}
   */
  public findShipmentOrder(shipmentOrder: ShipmentOrder): Observable<ShipmentOrder[]> {
    let criteria: any[] = [];
    criteria.push({ field: 'shipment_order.id', condition: '=', value: shipmentOrder.id });
    return this.http.get<ShipmentOrder[]>(ENVIRONMENT.API + '/shipment-order/get-shipment-orders?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description find inventory header by criteria and inventory rotation rules.
   * @param {PickingRf} picking - picking rf object.
   * @param {string} inventoryRotation - inventory rotation rule.
   * @returns {Observable<InventoryHeader>}
   */
  public findInventoryHeaderByInventoryRotation(picking: PickingRf, inventoryRotation: string):
    Observable<InventoryHeader> {
    let criteria: Object[];
    criteria = [
      { 'field': 'inventory_detail.product_id',
        'condition': '=',
        'value': picking.productId
      },
      {
        'field': 'inventory_header.location_id',
        'condition': '=',
        'value': picking.originHeader.locationId
      },
      {
        'field': 'inventory_detail.quantity',
        'condition': '>',
        'value': CONSTANTS.ZERO
      }];
    if (picking.originHeader.lpn.lpn) {
      criteria.push({
        'field': 'lpn.lpn',
        'condition': '=',
        'value': picking.originHeader.lpn.lpn
      });
    }
    if (picking.lot) {
      criteria.push({
        'field': 'inventory_detail.lot',
        'condition': '=',
        'value': picking.lot
      });
    }
    return this.http.get<InventoryHeader>(ENVIRONMENT.API + '/inventory/inventory-header-rotation/' + inventoryRotation +
      '?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Finds import number quantity
   * @param {number} productId producto identifier
   * @returns {Observable<Object>}
   */
  public findImportNumberQuantity(productId: number): Observable<Object> {
    return this.http.get<Object>(ENVIRONMENT.API + '/picking/import-number-quantity/' + productId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Set is available in lpn for pending work process
   * @param {string} lpn - lpn to validate
   * @return {Observable<Lpn>} - Lpn updated
   */
  public setIsAvailableLpn(lpn: string): Observable<Lpn> {
    let session: Session = this.sessionService.getUserSession();
    let lpnEncoded: string = encodeURIComponent(lpn);
    return this.http.post<Lpn>(ENVIRONMENT.API + '/lpn-reusable/set-is-available/' + lpnEncoded, JSON.stringify(session))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Make the change in  inventory status id
   * @param {PickingTaskWrapperDetail} picking The picking task object with the requiered information
   * @param {ShipmentOrderDetail} shipmentOrderDetail The order detail
   * @param {number} inventoryStatusId The new inventory status id
   * @returns {Observable<void>}
   */
  public changeStatusInPicking(picking: PickingTaskWrapperDetail, shipmentOrderDetail: ShipmentOrderDetail,
    inventoryStatus: InventoryStatus)
  : Observable<void> {
    let taskDetail: TaskDetail = new TaskDetail();
    taskDetail.orderedQuantity = picking.orderedQuantity;
    taskDetail.pickedQuantity = picking.pickedQuantity;
    taskDetail.id = picking.taskDetailId;
    taskDetail.lot = picking.lot || '';
    taskDetail.dynamicAttribute1 = picking.dynamicAttribute1 || '';
    taskDetail.dynamicAttribute2 = picking.dynamicAttribute2 || '';
    taskDetail.dynamicAttribute3 = picking.dynamicAttribute3 || '';
    let requestObj = {
      'originHeader': picking.originHeader,
      'destinationHeader': picking.originHeader,
      'productId': picking.product.id,
      'shipmentOrderDetail': shipmentOrderDetail,
      'inventoryStatus': inventoryStatus,
      'footprintDetail': picking.footprintDetail,
      'taskDetail': taskDetail
    };
    return this.http.post<void>(ENVIRONMENT.API + '/picking/change-status-in-picking', requestObj)
    .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Class set method information to picking by each process
   * @param {DataSessionObject} pickingByEachData Data Session Object
   * @return {void}
   */
  public setPickingByEachInfoInStorage(pickingByEachData: PickingByEachWrapper): void {
    localStorage.setItem('pickingByEachData', JSON.stringify(pickingByEachData));
  }

  /**
   * @description Class get method to obtain pickingByEachData
   * @return {PickingByEachWrapper}
   */
  public getPickingByEachInfoInStorage(): PickingByEachWrapper {
    return JSON.parse(localStorage.getItem('pickingByEachData'));
  }

  /**
   * @description Class set method information to inventory status
   * @param {InventoryStatus[]} inventoryStatuses Data Session Object
   * @return {void}
   */
  public setInventoryStatuspByEachData(inventoryStatuses: InventoryStatus[]): void {
    localStorage.setItem('inventoryStatuspByEachData', JSON.stringify(inventoryStatuses));
  }

  /**
   * @description Class get method to obtain inventory statuses
   * @return {InventoryStatus[]}
   */
  public getInventoryStatuspByEachData(): InventoryStatus[] {
    return JSON.parse(localStorage.getItem('inventoryStatuspByEachData'));
  }

  /**
   * @description Emits picking information via subject
   * @param {PickingByEachWrapper} pickingByEachData object picking by each information
   * @return {void}
   */
  public sendPickingByEachSubjectInformation(pickingByEachData: PickingByEachWrapper): void {
    this.subject.next(pickingByEachData);
  }

  /**
   * @description Gets the list of lpns.
   * @param {number} locationId Location's id.
   * @param {string} lpnEntered - entered lpn by user
   * @return {Observable<InventoryHeader[]>} List of inventory's header.
   */
  public getLPNInformationByLocationId(locationId: number, lpnEntered: string): Observable<InventoryHeader[]> {
    let session: Session = this.sessionService.getUserSession();
    let criteria: Criteria = new Criteria();
    criteria.addFilterWhere('lpn.lpn', lpnEntered);
    return this.http.get<InventoryHeader[]>(ENVIRONMENT.API + '/inventory/location/' + locationId + '?session=' + JSON.stringify(session)
      + '&criteria=' + criteria.stringify())
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Create a register for each scanned product
   * @param {PickingByEachTemporal} pickingByEachTemporalDetail PickingByEachTemporal object.
   * @return {Observable<PickingByEachTemporal[]>}
   */
  public addPickingByEachTemporalRegister(pickingByEachTemporalDetail: PickingByEachTemporal): Observable<PickingByEachTemporal[]> {
    let session: Session = this.sessionService.getUserSession();
    return this.http.post<PickingByEachTemporal[]>(ENVIRONMENT.API + '/pickingPiece/?session='
      + JSON.stringify(session), pickingByEachTemporalDetail).pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description updates the quantity that the user scans
   * @param {PickingByEachTemporal} pickingByEachTemporal Movement with the information which will be updated.
   * @return {Observable<PickingByEachTemporal[]>} - Updated Movement.
   */
  public updatePickingPieceTemp(pickingByEachTemporal: PickingByEachTemporal): Observable<PickingByEachTemporal[]> {
    let session: Session = this.sessionService.getUserSession();
    return this.http.put<PickingByEachTemporal[]>(ENVIRONMENT.API + '/pickingPiece/update-movement-picking-piece/?session='
      + JSON.stringify(session), pickingByEachTemporal).pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description updates the quantity that the user scans
   * @param {PickingByEachTemporal} pickingByEachTemporalStatus Movement with the information which will be updated.
   * @return {Observable<PickingByEachTemporal[]>} - Updated Movement.
   */
  public updatePickingPieceTempStatus(pickingByEachTemporalStatus: PickingByEachTemporal): Observable<PickingByEachTemporal[]> {
    return this.http.put<PickingByEachTemporal[]>(ENVIRONMENT.API + '/pickingPiece/update-movement-picking-piece-status/',
      pickingByEachTemporalStatus).pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
    * @description Delete by id the movements of picking by each on the temporal table.
   * @param {number} pickingByEachTemporalId PickingByEachTemporal's id to be deleted.
   * @returns {Observable<PickingByEachTemporal>}
   */
  public deleteTemporalPickingPieceById(pickingByEachTemporalId: number): Observable<PickingByEachTemporal> {
    return this.http.post<PickingByEachTemporal>(ENVIRONMENT.API + '/pickingPiece/delete-movement-by-id/' + pickingByEachTemporalId, null)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
    * @description Delete by id the movements of picking by each on the temporal table.
   * @param {PickingByEachTemporal} pickingByEachTemporal PickingByEachTemporal's id to be deleted.
   * @returns {Observable<PickingByEachTemporal>}
   */
  public deleteTemporalPickingPieces(pickingByEachTemporal: PickingByEachTemporal): Observable<void> {
    return this.http.post<void>(ENVIRONMENT.API + '/pickingPiece/delete-movements/', pickingByEachTemporal)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Pick and put process when close container
   * @param {PickingByEachTemporal} pickingByEachDetail - details temporal scanned by user
   * @param {Lpn} destinationLpn lpn validate and entered by user.
   * @return {Observable<InventoryHeader>}
   */
  public closeContainer(pickingByEachDetail: PickingByEachTemporal, destinationLpn: Lpn): Observable<InventoryHeader> {
    let requestObj: Object = {
      'lpn': destinationLpn,
      'pickingPiece': pickingByEachDetail,
    };

    return this.http.post<InventoryHeader>(ENVIRONMENT.API + '/pickingPiece/pick-put-process',
      requestObj).pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description change the status of a shipment order
   * @param {ShipmentOrder} shipmentOrder order which to change status
   * @returns {Observable<ShipmentOrder>}
   */
  public changePickingOrderStatus(shipmentOrder: ShipmentOrder): Observable<ShipmentOrder> {
    return this.http.put<ShipmentOrder>
      (ENVIRONMENT.API + '/pickingPiece/change-status-shipment-order/' + shipmentOrder.id, shipmentOrder)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets the list of pending works scanned.
   * @param {number} idPicking Picking Id when user entered a picking without order
   * @param {number} idShipmentOrder shipment order id information
   * @param {number} userId user id information
   * @param {number} warehouseId  warehouse selected id information
   * @return {Observable<PickingByEachTemporal[]>} List of pending works scanned.
   */
  public findPendingWork(idPicking?: number, idShipmentOrder?: number, userId?: number, warehouseId?: number): Observable<PickingByEachTemporal[]> {
    let criteria: Criteria = new Criteria();

    if (idPicking) {
      criteria.addFilterWhere('movement_picking_piece_scanned_temporal.picking_id', idPicking, '=');
    }

    if (idShipmentOrder) {
      criteria.addFilterWhere('movement_picking_piece_scanned_temporal.shipment_order_id', idShipmentOrder, '=');
    }

    if (userId) {
      criteria.addFilterWhere('movement_picking_piece_scanned_temporal.user_id', userId, '=');
    }

    if (warehouseId) {
      criteria.addFilterWhere('movement_picking_piece_scanned_temporal.warehouse_id', warehouseId, '=');
    }

    return this.http.get<PickingByEachTemporal[]>(ENVIRONMENT.API + '/pickingPiece/?criteria=' + criteria.stringify())
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Verify if location or pallet has inventory
   * @param {number} locationId Location id to find inventory or pallet
   * @param {string} lpn lpn to find inventory
   * @param {number[]} progressionIds - ids of specific progressions.
   * @param {number[]} productsIds - ids of specific products
   * @returns {Observable<InventoryDetail[]>} Found inventory detail information
   */
  public checkInventoryLpnToPickingByEach(locationId: number, lpn: string,
    progressionIds?: number[], productsIds?: number[]): Observable<InventoryDetail[]> {
    let criteria: Criteria = new Criteria();;

    criteria.addFilterWhere('inventory_detail.quantity', CONSTANTS.ZERO, '>');

    if (lpn) {
      criteria.addFilterWhere('lpn.lpn', lpn, '=');
      criteria.addFilterWhere('lpn.is_active', CONSTANTS.ONE, '=');
    }

    if (!_.isEmpty(progressionIds)) {
      criteria.addFilterWhere('inventory_detail.inventory_status_id', progressionIds, 'in');
    }

    if (!_.isEmpty(productsIds)) {
      criteria.addFilterWhere('inventory_detail.product_id', productsIds, 'in');
    }

    let criteriaText: string = JSON.stringify(criteria);
    return this.http.get<InventoryDetail[]>(ENVIRONMENT.API + `/pickingPiece/check-lpn/${locationId}/?criteria=${criteriaText}`)
      .pipe(catchError(HandleError.handleErrorObservable));
  }
}
